import { getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import basicMixin from "@/mixins/basic"
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all records from materials crushing activities table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export function getAllMaterialCrushingActivities(reportId) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function(resolve) {
            const materialActivity = getRepository("oreReportMaterialCrushingActivities")
                .find({
                    where: { report: reportId, deleted: null }
                }).then(response => { store.commit("ore/setUpdateReportsSelected", { 'material_crushing_activities': response });
                    resolve(response); })
                .catch(error => {console.log(error); resolve(undefined)})
        })
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/material-crushing-activities/all/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error)
                })
        })
    }
}

/**
 * It is used to delete records from materials crushing activities table by material_crush_uuid.
 * @param {String} materialUUID - Material parent UUID.
 * @returns {Array} All records available.
 */
export function deleteAllMaterialCrushingActivitiesBymaterialUUID(
    materialUUID
) {
    return new Promise(function (resolve) {
        const materialActivity = getRepository(
            "oreReportMaterialCrushingActivities"
        );
        let user_id = store.getters["auth/getAuthCustomer"].data.id;
        materialActivity
            .update({material_crush_uuid: materialUUID}, {
                update: new Date(),
                update_by: user_id,
                deleted: new Date(),
                deleted_by: user_id,
                sync: false,
            })
            .then(() => {
                materialActivity
                    .find({
                        where: { material_crush_uuid: materialUUID },
                    })
                    .then((res) => {
                        return sync(res);
                    });
            })
            .catch((error) => {
                console.log(error);
                resolve(undefined);
            });
    });
}

/**
 * It gets a record from materials crushing activities to edit
 * @param {Number} id - Material Id.
 * @returns {Object} The record to edit
 */
export function getMaterialCrushingActivities(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function(resolve) {
            const materialActivity = getRepository("oreReportMaterialCrushingActivities")
                .findOne({ id: id, deleted: null, }).then(response => { resolve(response); })
                .catch(error => {console.log(error); resolve(undefined)})
        })
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/material-crushing-activities/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error)
                })
        })
    }
}

/**
 * It makes a soft delete of a record from materials crushing activities table
 * @param {Number} id - Material Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteMaterialCrushingActivities(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const materialActivity = getRepository("oreReportMaterialCrushingActivities");
        let user_id = store.getters["auth/getAuthCustomer"].data.id

        await materialActivity.update(id, {
            update: new Date(),
            update_by: user_id,
            deleted: new Date(),
            deleted_by: user_id,
            sync: false,
        }).then(() => {}).catch(error => console.log(error))
        await materialActivity.find({ id: id }).then((res) => { return sync(res); });
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/material-crushing-activities/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error)
                })
        })
    }

}

/**
 * It creates a new record in the materials crushing activities table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createMaterialCrushingActivities(data) {
    data["uuid"] = basicMixin.methods.createUUID()
    data["report"] = store.getters['ore/getReportId']
    let isMobile = isConfigMobile()
    if (isMobile) {
        const materialActivity = getRepository("oreReportMaterialCrushingActivities");
        let now = new Date();
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        data.update = now;
        data.update_by = user_id;
        data.created = now;
        data.created_by = user_id;
        await materialActivity.save(data)
            .then((res) => { return sync([res]); }).catch(error => console.log(error))
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/material-crushing-activities/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error)
                })
        })
    }
}

/**
 * It updates a record in the materials crushing activities table
 * @param {Number} id - Material Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateMaterialCrushingActivities(id, data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const materialActivity = await getRepository("oreReportMaterialCrushingActivities");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await materialActivity.update(id, {
            activity: data.activity,
            product: data.product,
            qty: data.qty,
            material_crush_uuid: data.material_crush_uuid,
            update: new Date(),
            update_by: user_id,
            sync: false,
        }, )
        await materialActivity.find({ id: id, deleted: null }).then(res => { return sync(res) });
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/material-crushing-activities/' + id + "/", data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error)
                })
        })
    }
}


export async function updateAllMaterialCrushingActivities(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const materialActivity = await getRepository("oreReportMaterialCrushingActivities");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await materialActivity.update(id, {
            activity: data.activity,
            product: data.product,
            qty: data.qty,
            material_crush_uuid: data.material_crush_uuid,
            update: new Date(),
            update_by: user_id,
            sync: false,
        }, )
        await materialActivity.find({ id: id, deleted: null }).then(res => { return sync(res) });
    } else {
        return new Promise(function(resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/material-crushing-activities/all/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error) 
                })
        })
    }
}
/**
 * It is used to synchronize data from materials module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    const reportRepository = getRepository("oreReport");
    const materialActivity = await getRepository("oreReportMaterialCrushingActivities");
    let data_material = parseDate(data);

    let reportData = await reportRepository.find({ id: data_material[0].report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine = store.getters["comun/getIsOnline"]
    if (checkOnLine) {
        let objToSync = {
            reports: [{
                report: reportData[0],
                data: { material_crushing_activities: data_material }
            }],
        }

        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function(response) {
                let id = data_material[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("material_crushing_activities" in dataRes) {
                    let _items = dataRes.material_crushing_activities
                    //If the sync is rejected, the item is updated with the information that comes from web.
                    _items.forEach(async(item) => {
                        if (item.action_msg == "reject") {
                            await materialActivity.update(id, {
                                activity: item.activity,
                                product: item.product,
                                qty: item.qty,
                                material_crush_uuid: item.material_crush_uuid,
                                uuid: item.uuid,
                                created: new Date(item.created),
                                created_by: item.created_by,
                                update: new Date(item.update),
                                update_by: item.update_by,
                                web_id: item.web_id,
                                update_web: new Date(item.update_web),
                                sync: true,
                            }, ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await materialActivity.update(id, {
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                }, ).then((res) => {}).catch(error => console.log(error))
                            }
                        }
                    })
                }
                store.commit("comun/setSynchronizing", false);
                return getAllMaterialCrushingActivities(data_material[0].report)
            }).catch(error => { store.commit("comun/setSynchronizing", false);
                console.log(error); return getAllMaterialCrushingActivities(data_material[0].report) })
    } else {
        store.commit("comun/setSynchronizing", false);
        return getAllMaterialCrushingActivities(data_material[0].report)
    }
}